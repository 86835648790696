/* file-autogenerated by @takeda-digital/xr on 2024-06-10 at 17:27:56.008 UTC */
@tailwind base;
@tailwind components;
@tailwind utilities;

/*
    Chrome has a bug with transitions on load since 2012!
    To prevent a "pop" of content, you have to disable all transitions until
    the page is done loading.
    https://lab.laukstein.com/bug/input
    https://twitter.com/timer150/status/1345217126680899584
    */
body.loading * {
  transition: none !important;
}

html,
body {
  height: 100%;
  width: 100%;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#__next {
  isolation: isolate;
  margin: 0;
  padding: 0;
}
